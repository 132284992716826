<template>
  <div id="customerEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="customersData">
              <div class="vx-col w-full">
                <h1 v-if="customersData.id">Uredi
                  {{ !customersData.gender || customersData.gender === 'male' ? 'uporabnika' : 'uporabnico' }} <span
                    v-if="customersData.firstName && customersData.lastName">"{{ customersData.firstName }} {{ customersData.lastName }}"</span>
                </h1>
                <h1 v-if="!customersData.id">Dodaj uporabnika</h1>
              </div>
            </div>

            <div class="vx-row mt-5">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Ime" v-model="customersData.firstName"/>

                <div class="flex mt-1" v-if="$v.customersData.firstName.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.customersData.firstName.required" :color="'danger'">
                      Polje "Ime" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>
            </div>

            <div class="vx-row mt-5">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Priimek" v-model="customersData.lastName"/>

                <div class="flex mt-1" v-if="$v.customersData.lastName.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.customersData.lastName.required" :color="'danger'">
                      Polje "Priimek" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>
            </div>


            <div class="flex mt-5">
              <div class="w-1/2">
                <div class="grid grid-cols-1">
                  <label for="gender" class="vs-input--label mb-2">Spol</label>

                  <ul class="genderPicker" id="gender">
                    <li class="ml-1">
                      <vs-radio v-model="customersData.gender" vs-value="female">ženski</vs-radio>
                    </li>
                    <li class="ml-10">
                      <vs-radio v-model="customersData.gender" vs-value="male">moški</vs-radio>
                    </li>
                  </ul>

                </div>
              </div>
              <div class="w-1/2">
                <div class="grid grid-cols-1">
                  <label class="vs-input--label">Datum rojstva</label>
                  <datepicker placeholder="Datum rojstva" :language="slSI" :format="customDateFormatter"
                              :monday-first="true"
                              v-model="customersData.birthday"></datepicker>
                </div>
              </div>
            </div>


            <div class="flex mt-5">
              <div class="w-1/2">
                <div class="grid grid-cols-1 mt-5">
                  <label for="active" class="vs-input--label mb-2">Uporabnik je aktiven</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.active" id="active">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>

                <!--            <div class="grid grid-cols-1 mt-5">-->
                <!--              <label for="vaccinated" class="vs-input&#45;&#45;label mb-2">Uporabnik je prejel cepivo proti COVID-19</label>-->
                <!--              <vs-switch class="ml-1" color="success" v-model="customersData.vaccinated" id="vaccinated">-->
                <!--                <span slot="on">DA</span>-->
                <!--                <span slot="off">NE</span>-->
                <!--              </vs-switch>-->
                <!--            </div>-->

                <div class="grid grid-cols-1 mt-5">
                  <label for="smartPhoneUser" class="vs-input--label mb-2">Uporabnik uporablja pametni telefon</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.smartPhoneUser" id="smartPhoneUser">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>

                <div class="grid grid-cols-1 mt-5">
                  <label for="notifiedAboutCoupons" class="vs-input--label mb-2">Uporabnik je obveščen o kuponih
                    ugodnosti</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.notifiedAboutCoupons"
                             id="notifiedAboutCoupons">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>
              </div>
              <div class="w-1/2">
                <div class="grid grid-cols-1 mt-5">
                  <label for="active" class="vs-input--label mb-2">Ima parkirno karto za invalide</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.ownsParkingPermitForDisabled" id="active">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>

                <div class="grid grid-cols-1 mt-5">
                  <label for="active" class="vs-input--label mb-2">Uporablja pripomoček pri hoji (hodulja, zložljiv voziček, bergle)</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.usesWalkingAid" id="active">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>

                <div class="grid grid-cols-1 mt-5">
                  <label for="active" class="vs-input--label mb-2">Pri opravkih potrebuje spremstvo</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.needsEscort" id="active">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>

                <div class="grid grid-cols-1 mt-5">
                  <label for="active" class="vs-input--label mb-2">Ima posebnosti pri vedenju (znaki demence ipd.)</label>
                  <vs-switch class="ml-1" color="success" v-model="customersData.hasSpecialBehaviouralNeeds" id="active">
                    <span slot="on">DA</span>
                    <span slot="off">NE</span>
                  </vs-switch>
                </div>
              </div>
            </div>

            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Naslov bivališča uporabnika</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1">
                    <label for="customersAddress" class="vs-input--label">Naslov</label>

                    <gmap-autocomplete
                      ref="customersAddress"
                      id="customersAddress"
                      @place_changed="(data) => { getAddressData(data, 'customers') }"
                      placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                      :value="customersData.address.quickSearch"
                      :options="{componentRestrictions: { country: 'si' }}"
                      :select-first-on-enter="true"
                      class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                  </div>

                  <div class="flex mt-1" v-if="$v.customersData.address.quickSearch.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.customersData.address.quickSearch.required" :color="'danger'">
                        Polje "Naslov" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>


                  <div class="flex mt-5">
                    <div class="w-3/4">
                      <vs-input class="w-full" label="Naziv naslova (ime naselja, vasi oz. ustanove)"
                                v-model="customersData.addressName"/>
                    </div>
                    <div class="w-1/4 ml-5">
                      <vs-input class="w-full" label="Oddaljenost od mestnega središča" disabled
                                v-model="customersData.addressDistanceFromCityCentre"/>
                    </div>
                  </div>


                  <div slot="header" class="p-0 text-primary mt-5">
                    Podrobnosti naslova
                  </div>

                  <div class="collapseBody mt-5">

                    <div class="flex mb-4">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Ulica" v-model="customersData.address.route"/>

                        <div class="flex mt-1" v-if="$v.customersData.address.route.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.customersData.address.route.required" :color="'danger'">
                              Polje "Ulica" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Hišna številka"
                                  v-model="customersData.address.streetNumber"/>

                        <div class="flex mt-1" v-if="$v.customersData.address.streetNumber.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.customersData.address.streetNumber.required" :color="'danger'">
                              Polje "Hišna številka" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex mb-4">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Naziv pošte" v-model="customersData.address.postalTown"/>

                        <div class="flex mt-1" v-if="$v.customersData.address.postalTown.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.customersData.address.postalTown.required" :color="'danger'">
                              Polje "Naziv pošte" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Poštna številka"
                                  v-model="customersData.address.postalCode"/>

                        <div class="flex mt-1" v-if="$v.customersData.address.postalCode.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.customersData.address.postalCode.required" :color="'danger'">
                              Polje "Poštna številka" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex mb-4">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Občina" v-model="customersData.address.municipality"/>

                        <div class="flex mt-1" v-if="$v.customersData.address.municipality.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.customersData.address.municipality.required" :color="'danger'">
                              Polje "Občina" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Država" v-model="customersData.address.country"/>

                        <div class="flex mt-1" v-if="$v.customersData.address.country.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.customersData.address.country.required" :color="'danger'">
                              Polje "Država" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="flex mt-5">
              <div class="w-1/3">
                <vs-input class="w-full" label="Telefon" v-model="customersData.phone"/>

                <div class="flex mt-1" v-if="$v.customersData.phone.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.customersData.phone.required" :color="'danger'">
                      Polje "Telefon" ne sme biti prazno.
                    </vs-alert>
                  </div>
                </div>

              </div>

              <div class="w-1/3 ml-5">
                <vs-input class="w-full" label="Telefon 2" v-model="customersData.phone2"/>
              </div>

              <div class="w-1/3 ml-5">
                <vs-input class="w-full" label="Telefon svojca oz. skrbnika" v-model="customersData.caretakerPhone"/>
              </div>
            </div>

            <div class="vx-row mt-5">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Email" v-model="customersData.email"/>
              </div>
            </div>

            <div class="flex mt-5">
              <div class="w-full">
                <label class="vs-input--label">Opombe o uporabniku</label>
                <vs-textarea class="w-full" rows="6" v-model="customersData.notes"/>
              </div>
            </div>

            <div class="flex mt-5">
              <div class="w-full">
                <label class="vs-input--label">Opombe koordinatorja <small><em>(te opombe niso vidne drugim osebam)</em></small></label>
                <vs-textarea class="w-full" rows="6" v-model="customersData.coordinatorNotes"/>
              </div>
            </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3" v-if="customersData">
          <template>

            <div class="vx-card mt-20" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Bivališče uporabnika</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-0">

                  <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">

                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                                      @closeclick="infoWinOpen=false"/>
                    <GmapMarker
                      :key="index"
                      v-for="(item, index) in markers"
                      :position="item.position"
                      :clickable="true"
                      :draggable="false"
                      @click="toggleInfoWindow(item, index)"
                    />

                    <DirectionsRenderer/>


                  </gmap-map>

                </div>
              </div>
            </div>

            <!--            <div class="vx-card mt-10" style="overflow: hidden">-->
            <!--              <div class="vx-card__header">-->
            <!--                <h4 class="text-success mb-0">Soglasja {{!customersData.gender || customersData.gender === 'male' ?-->
            <!--                  'uporabnika' : 'uporabnice'}}</h4>-->
            <!--              </div>-->
            <!--              <div class="vx-card__collapsible-content">-->
            <!--                <div class="vx-card__body">-->

            <!--                  <div v-if="customersData && customersData.id">-->
            <!--                    <ConsentsWidget :user-id="customersData.id" :consent-giver="'CUSTOMER'" :mode="'edit'"></ConsentsWidget>-->
            <!--                  </div>-->

            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </template>
        </div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
            <vs-button v-if="!customerId" size="large" color="success" icon="save" @click="createCustomer">Ustvari
              uporabnika
            </vs-button>
            <vs-button v-if="customerId" size="large" color="success" icon="save" @click="updateCustomer">Posodobi
              uporabnika
            </vs-button>
          </vs-col>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import vSelect from "vue-select";
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import ConsentsWidget from "../consentManagement/ConsentWidget";
import {minLength, required} from "vuelidate/lib/validators";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'customer-edit',

  components: {
    DirectionsRenderer,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
    ConsentsWidget
  },
  props: {
    customerId: Number
  },
  data() {
    return {
      allCustomersData: null,
      slSI: slSI,
      center: {lat: 46.0569, lng: 14.5058},

      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      routeDistanceTotal: null,

      customerData: null,
      customer: null,

      markers: [],

      customersData: {
        "id": null,
        "firstName": null,
        "lastName": null,
        "phone": null,
        "phone2": null,
        "caretakerPhone": null,
        "email": null,
        "notes": null,
        "addressId": null,
        "unitId": null,
        "addressName": null,
        "addressDistanceFromCityCentre": null,
        "customers": null,
        "gender": 'female',
        "active": true,
        "address": {},
      }
    }
  },

  validations: {
    customersData: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      phone: {
        required
      },
      address: {
        quickSearch: {
          required
        },
        route: {
          required
        },
        streetNumber: {
          required
        },
        postalCode: {
          required
        },
        postalTown: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      }
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.checkIfGoogleMapsApiLoaded().then((res) => {
      console.log("DONE");

      if (_this.customerId) {
        _this.getCustomer();
      } else {
        _this.getCustomers();
        console.log("kaj pa zdej!?!");
      }
    });
  },

  watch: {},

  methods: {
    async checkIfGoogleMapsApiLoaded() {
      const _this = this;

      let promise = new Promise(function (resolve, reject) {
        if (typeof google !== 'undefined') {
          _this.showUnitMarker();
          resolve("done")
        } else {
          setTimeout(() => {
            _this.checkIfGoogleMapsApiLoaded()
          }, 400);
        }
      });
    },
    showCustomerMarker() {
      const _this = this;

      geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({'address': _this.customersData.address.quickSearch}, function (results, status) {
        if (status === 'OK') {
          _this.center = results[0].geometry.location;

          console.log("_this.center", results[0].geometry.location);

          let position = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }

          _this.markers.push({
            infoText: _this.customersData.firstName + ' ' + _this.customersData.lastName,
            position: position
          });

          _this.setMapsDirections();

        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });

    },

    async showUnitMarker() {
      const _this = this;

      geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({'address': this.$globalFunctions.getCurrentUnit().locationForContracts + ', Slovenija'}, function (results, status) {
        if (status === 'OK') {
          if (!_this.customersData.address.quickSearch) {
            _this.center = results[0].geometry.location;
          }

          let position = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }

          _this.markers.push({
            infoText: _this.$globalFunctions.getCurrentUnit().name,
            position: position
          });

        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    },

    async getCustomer() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(`${Vue.prototype.$config.api.sopotnikiVPS}customers/${_this.customerId}`)
        .then((res) => {
          console.log("customersData: ", res.data.data);
          _this.customersData = res.data.data;
          _this.showCustomerMarker();
          _this.$vs.loading.close();

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },


    setMapsDirections() {
      const _this = this;

      if (!directionsService) {
        directionsService = new google.maps.DirectionsService;
        directionsDisplay = new google.maps.DirectionsRenderer;
        directionsDisplay.setMap(_this.$refs.map.$mapObject);
      }

      directionsService.route({
        origin: _this.customersData.address.quickSearch,
        destination: _this.$globalFunctions.getCurrentUnit().locationForContracts + ', Slovenija',
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING
      }, function (response, status) {
        if (status === 'OK') {
          _this.customersData.addressDistanceFromCityCentre = Math.round(response.routes[0].legs[0].distance.value / 1000);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      });
    },


    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData", addressData);

      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.customersData.address.quickSearch = addressData.formatted_address;
      _this.customersData.address.route = addressData.address_components[1].long_name;
      _this.customersData.address.streetNumber = addressData.address_components[0].long_name;
      _this.customersData.address.postalTown = addressData.address_components[2].long_name;
      _this.customersData.address.postalCode = addressData.address_components[5].long_name;
      _this.customersData.address.municipality = addressData.address_components[3].long_name;
      _this.customersData.address.country = addressData.address_components[4].long_name;


      if (!directionsService) {
        directionsService = new google.maps.DirectionsService;
        directionsDisplay = new google.maps.DirectionsRenderer;
        directionsDisplay.setMap(_this.$refs.map.$mapObject);
      }

      let position = {
        'lat': addressData.geometry.location.lat(),
        'lng': addressData.geometry.location.lng()
      };

      _this.markers[0] = {
        infoText: 'Bivališče',
        position: position
      };
      _this.center = position;

      _this.$forceUpdate();

      _this.setMapsDirections();

    },

    createCustomer() {
      const _this = this;

      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      // check for duplicate
      if (!_this.customersData.id) {
        let customersOnSameAddress = _.filter(_this.allCustomersData, {address: {quickSearch: _this.customersData.address.quickSearch}});

        if (customersOnSameAddress) {
          let duplicatedCustomer = false;
          _.each(customersOnSameAddress, function (singleCustomer) {
            if (singleCustomer.firstName === _this.customersData.firstName && singleCustomer.lastName === _this.customersData.lastName) {
              duplicatedCustomer = true;
              Vue.$confirm({
                title: 'Uporabnik/-ca že obstaja!',
                message: 'Uporabnik/-ca s takšnim imenom, priimkom in naslovom bivanja že obstaja.',
                button: {
                  yes: 'Poglej obstoječega uporabnika/-co',
                  no: 'Prekliči'
                },
                callback: async confirm => {
                  if (confirm) {
                    _this.customerId = singleCustomer.id;
                    _this.$router.push({name: 'Uporabnik', params: {customerId: singleCustomer.id}});
                    await _this.getCustomer();
                  }
                }
              });
              return false;
            }
          });

          if (duplicatedCustomer) {
            return false;
          }

        }
      }


      let customer = _.omit(_this.customersData, ['disabled', 'deleted', 'createdAt', 'updatedAt', 'units', 'customers']);
      customer.address = _this.customersData.address;
      customer.unitId = _this.customersData.unitId ? _this.customersData.unitId : _this.$globalFunctions.getCurrentUnit().id;

      console.log(customer);

      _this.$vs.loading();

      Vue.prototype.$http.post(`${Vue.prototype.$config.api.sopotnikiVPS}customers`, customer)
        .then((res) => {

          console.log("res", res);
          _this.$vs.loading.close();
          _this.$router.push({name: 'Uporabniki'});

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    updateCustomer() {
      const _this = this;

      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      let customer = _.omit(_this.customersData, ['disabled', 'deleted', 'createdAt', 'updatedAt', 'units', 'customers']);
      customer.address = _this.customersData.address;
      customer.unitId = _this.customersData.unitId ? _this.customersData.unitId : _this.$globalFunctions.getCurrentUnit().id;


      console.log("customer to update", customer);

      _this.$vs.loading();

      Vue.prototype.$http.put(`${Vue.prototype.$config.api.sopotnikiVPS}customers/${customer.id}`, customer)
        .then((res) => {
          console.log("res", res);
          _this.$vs.loading.close();
          _this.$router.push({name: 'Uporabniki'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },


    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert').getBoundingClientRect();
      window.scrollTo(
        domRect.left + document.documentElement.scrollLeft,
        domRect.top + document.documentElement.scrollTop - 200, 'smooth'
      );
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    getCustomers() {
      const _this = this;

      _this.$vs.loading();


      Vue.prototype.$http.get(`${Vue.prototype.$config.api.sopotnikiVPS}customers?unit=${_this.$globalFunctions.getCurrentUnit().id}`)
        .then((res) => {

          _this.allCustomersData = res.data.data;
          console.log("TOLE: ", res.data.data);

          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

  }
}
</script>

